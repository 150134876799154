import React, {useState} from "react"
import { Link } from "gatsby"

const ImageNavbar = ({images}) => {
  const [hover, setHover] = useState({})  
  return(
    <div>
        {images.map((it, idx) =>
        <div 
            style={{
                // backgroundImage:hover.index!==idx||hover.index===undefined?`url(${it.img})`:null, 
                backgroundImage:`url(${it.img})`, 
                backgroundSize: 'cover',
                width:hover.index===idx?'100%':'50%',
                height:hover.index===idx?200:100,
                //opacity:hover.index===idx?1.0:1.0,
                //border:hover.index===idx?'10px solid ' + it.backgroundColor:'none', 
                //backgroundColor:hover.index!==undefined?hover.index===idx?it.backgroundColor:'transparent':'transparent', 
                margin:6, 
                padding:0, 
                transition:'1.0s ease',
            }}
            onMouseEnter={()=>setHover({index:idx})}
            onMouseLeave={()=>setHover({})}
        >
            <Link to={it.link}>
            <div style={{
                display:'flex',
                backgroundColor:hover.index===idx?'rgba(0,255,255,0.2)':it.backgroundColor, 
                width:'100%', 
                height:'100%',
                transition:'1.0s ease',
                }}
            >
                <span style={{
                        margin:'auto',
                        color:hover.index===idx?'blue':'white', 
                        fontSize:hover.index===idx?'medium':'x-large', 
                        transition:'1.0 ease',
                        zIndex:10,
                }}>
                    {hover.index===idx?it.helpText['EN']:it.title['EN']}
                </span>
            </div>
            </Link>
        </div>  
        )}  
    </div>
  )
}

export default ImageNavbar
