import React from "react"
import { connect } from 'react-redux';
import { Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ImageExpand from "../components/imageExpand"
import { toggleDarkMode } from '../state/app';
import { setNavbarKey } from '../state/navbar';

const images=[
  {img:require('../images/homepage/start_nude_1x_1.png'), 
    backgroundColor:'rgba(0, 0, 255, 0.5)', 
    link:'', 
    title:{EN:'Page 1', SV:'Sida 1'},
    helpText:{EN:'This is a help text for page 1', 
              SV:'Cursos, Registro, Baile social, Lecciones, Acuredo de negocios, Maestro'
    },
  },
  {img:require('../images/homepage/start_nude_1x_2.png'), 
  backgroundColor:'rgba(0, 255, 0, 0.5)', 
  link:'/page-2', 
    title:{EN:'Page 2', SV:'Sida 2'},
    helpText:{EN:'This is a help text for page 2', 
              SV:'Calendar with acitivities',
    }, 
  },
]


const Page = ({isDarkMode, rootdir, subdir, dispatch}) => (
  <Layout>
    <SEO title="Page five" />
    <h1>Hi from the 5-th page</h1>
    <p>Welcome to Per Eskilsons page 5</p>
    <ImageExpand images={images} />
    <Link to="/">Go back to the homepage</Link>
    <button
      style={isDarkMode ? { background: 'black', color: 'white' } : null}
      onClick={() => dispatch(toggleDarkMode(!isDarkMode))}>Dark mode {isDarkMode ? 'on' : 'off'}
    </button>
    <input type='text' placeholder={'rootdir'} onChange={(e) => {dispatch(setNavbarKey('rootdir', e.target.value))
                                                                 dispatch(setNavbarKey('subdir', undefined))}}/>
    <input type='text' placeholder={'subdir'} onChange={(e) => dispatch(setNavbarKey('subdir', e.target.value))} />
    <h1>Rootdir: {rootdir}&nbsp;Subdir:{subdir}</h1>
  </Layout>
)

const mapStateToProps = state => ({
  isDarkMode: state.app.isDarkMode,
  rootdir:state.navbar.rootdir,
  subdir:state.navbar.subdir,
})

export default connect(mapStateToProps, null)(Page)
